import React from 'react'
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Footer from './include/Footer';

import {BrowserRouter,Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import OurWork from './components/OurWork';
import Header from './include/Header';
import ContactUs from './components/ContactUs';
import Careers from './components/Careers';
import Team from './components/Team';
import Blogs from './components/Blogs';
import SubmitResume from './components/SubmitResume';
import ScrollToTop from './helper/ScrollToTop';
import PrivacyPolicy from './components/PrivacyPolicy';
import Digital from './components/Digital';
import Creative from './components/Creative';
import Retail from './components/Retail';
import Events from './components/Events';
import Detail from './components/Detail';
import { Helmet } from 'react-helmet';

function App() {

  const formik = useFormik({
    initialValues: {
      username: '',
      name: '',
      email: '',
      status: '',
      country: '',
    },
    validationSchema:Yup.object({
      username:Yup.string()
      .max(10,'username must be less than or equal to 10 char')
      .required("This is a required field"),

      name:Yup.string()
      .required("This is an required field"),

      email:Yup.string()
      .required("This is an required field")
      .email("Please provide a valid email"),

      status:Yup.string()
      .required("This is an required field"),

      country:Yup.string()
      .required("This is an required field"),
  
    }),
    onSubmit: (value,e) => {
      // e.preventDefault();
      // console.warn(name, email, mobile, ctc, exp, coverLetter);
      // let data = { name, email, mobile, ctc, exp, coverLetter }
      // console.log(name);
      let formData = new FormData();
      formData.append('username', value.username);
      formData.append('name', value.name);
      formData.append('email', value.email);
      formData.append('status', value.status);
      formData.append('country', value.country);
     
  
  
      let response =  fetch('https://hsadindia.co.in/admin/api-submit-resume', {
        method: 'POST',
  
  
        body: formData,
        // headers: {
        //   // 'Content-type': 'application/json; charset=UTF-8',
        //   'Content-type': 'multipart/form-data; ',
        // },
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.status ==='1'){
            Swal.fire({
              //position: 'top-end',
              icon: 'success',
              title: data.msg,
              showConfirmButton: false,
              timer: 1500
            })
          }else{
            Swal.fire({
              //position: 'top-end',
              icon: 'error',
              title: data.data,
              showConfirmButton: false,
              timer: 1500
            })
          }
         
  
          // setPosts((posts) => [data, ...posts]);
          // setTitle('');
          // setBody('');
        })
        .catch((err) => {
          console.log(err.message);
        });

      console.log("form submitted",e)

    }
  })


  // async function submitResume(e) {
  //   e.preventDefault();
  //     // console.warn(name, email, mobile, ctc, exp, coverLetter);
  //     // let data = { name, email, mobile, ctc, exp, coverLetter }
  //     // console.log(name);
  //     let formData = new FormData();
  //     // formData.append('name', name);
     
  
  
  //     let result = await fetch('https://hsadindia.co.in/admin/api-submit-resume', {
  //       method: 'POST',
  
  
  //       body: formData,
  //       // headers: {
  //       //   // 'Content-type': 'application/json; charset=UTF-8',
  //       //   'Content-type': 'multipart/form-data; ',
  //       // },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if(data.status =='1'){
  //           Swal.fire({
  //             //position: 'top-end',
  //             icon: 'success',
  //             title: data.msg,
  //             showConfirmButton: false,
  //             timer: 1500
  //           })
  //         }else{
  //           Swal.fire({
  //             //position: 'top-end',
  //             icon: 'error',
  //             title: data.data,
  //             showConfirmButton: false,
  //             timer: 1500
  //           })
  //         }
         
  
  //         // setPosts((posts) => [data, ...posts]);
  //         // setTitle('');
  //         // setBody('');
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  
  
  //       // alert("Data has been saved");
      
  
  //   }

   console.log(formik.errors)
   console.log(formik.touched)

  // return (

  //   <div className='App'>
  //     <form onSubmit={ formik.handleSubmit }>

  //       <input type='text' name='username' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.username} placeholder="Username"/><br></br>
  //       {formik.touched.username && formik.errors.username && <p style={{color:"red"}}>{formik.errors.username} </p>}
        
  //       <br></br><input type='name' name='name' onChange={formik.handleChange} onBlur={formik.handleBlur}  value={formik.values.name} placeholder="name" /><br></br>
  //       {formik.touched.name && formik.errors.name && <p style={{color:"red"}}>{formik.errors.name} </p>}
        
  //       <br></br><input type='email' name='email' onChange={formik.handleChange} onBlur={formik.handleBlur}  value={formik.values.email} placeholder="email" /><br></br>
  //       {formik.touched.email && formik.errors.email && <p style={{color:"red"}}>{formik.errors.email} </p>}
        
  //       <br></br><span>Status :</span><br></br>  <br></br>
  //       Single    <input type='radio' name='status' onChange={formik.handleChange} onBlur={formik.handleBlur}  value="single"  /><br></br>
  //       Commited  <input type='radio' name='status' onChange={formik.handleChange} onBlur={formik.handleBlur}  value="commited"  /><br></br>
  //       Coder     <input type='radio' name='status' onChange={formik.handleChange} onBlur={formik.handleBlur}  value="coder"  /><br></br>
  //       <br></br>
  //       {formik.touched.status && formik.errors.status && <p style={{color:"red"}}>{formik.errors.status} </p>}

  //       Country
  //       <select name='country' onChange={formik.handleChange}  onBlur={formik.handleBlur} >
  //         <option value="" >Select Country</option>
  //         <option value="India" >India</option>
  //         <option value="USA" >USA</option>
  //         <option value="Japan" >Japan</option>

  //       </select>
  //       {formik.touched.country && formik.errors.country && <p style={{color:"red"}}>{formik.errors.country} </p>}



  //       <button type='submit'> SUBMIT</button>


  //     </form>  
  //   </div>
  // );
  return (
    <>
     
     <BrowserRouter> 
       <ScrollToTop />
      <Header/>
       <Helmet>
        <title>HSAD India | Strategic Brand Growth & Creative Solutions</title>
        <meta name="description"content="HSAD India helps business houses drive brand growth through strategic advertising, cutting-edge technology, and creative excellence. Elevate your brand today!"/>
        <meta name="keywords" content="" />
       </Helmet>
        {/* <Switch  asename={"/dev"}> */}
        <Switch >
           <Route exact path="/" component={Home}/>
           <Route  path="/our-work" component={OurWork}/>
           <Route path="/team" component={Team}/>
           <Route path="/blogs" component={Blogs}/>
           <Route path="/careers" component={Careers}/>
           <Route path="/contact-us" component={ContactUs}/>
           <Route path="/submit-resume/:name"  component={SubmitResume}/>
           <Route path="/detail/:id"  component={Detail}/>
           <Route path="/privacy-policy" component={PrivacyPolicy}/>
           <Route path="/digital" component={Digital}/>
           <Route path="/creative" component={Creative}/>
           <Route path="/retail" component={Retail}/>
           <Route path="/events" component={Events}/>
        </Switch>
        
         <Footer/>
     
         </BrowserRouter>
    </>
  );
}

export default App;
